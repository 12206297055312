@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.marketReports-content {
  width: 80%;
  margin: 5% auto;
  min-height: calc(70vh - 100px)
}

.content-box {
  display: flex;
  flex-direction: column;
}

.date-container {
  text-align: left; /* Default alignment */
}

.urdu-date {
  text-align: right; /* Align right if the first content is in Urdu */
}

.report-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title-container,
.description-container {
  text-align: left;
}

.urdu-content {
  direction: rtl; /* Aligning the direction for Urdu content */
}

.urdu-text {
  text-align: right;
}
.tittle{
  margin-top: 0;
}