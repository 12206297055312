.cardContainer{
    gap: 0.2vw;
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-family: "Poppins", sans-serif;
    min-height: calc(100vh - 100px)
}
.cardImage{
    width: 80%;
}
.card-row{
    /* width: 400px; */
    width: 30%;

    margin-top: 5%;
}
.readMore-btn{
    border: none;
    padding: 2% 5%;
    font-size: 16px;
    border-radius: 15px;
    font-weight: 600;
    background-color: green;
    color: #fff;
    font-family: "Poppins", sans-serif;

}
.date{
    color: grey;
    font-weight: 500;
}
.aboutBlog{
    font-weight: 500;
    color: gray;
}
.blogContent{
    width: 90%;
    margin: auto;
    font-family: "Poppins", sans-serif;
    min-height: calc(90vh - 100px)

}
.blogContent p{
    color: grey;
    font-weight: 500;
}


.blogInputs{
    margin-top:10px;
    margin-left: 20px;
    max-width: 200px;
  }
  /* admin panel */
  .add-blog-container {
    max-width: 500px;
    align-content: center;
    margin: 5% auto;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    min-height: calc(70vh - 100px)
  }
  
  .add-blog-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 26px;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .blog-input {
    width: 90%;
    padding: 15px 5%;
    margin-bottom: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    font-size: 16px;
    color: #333;
  }
  
  .blog-content {
    height: 150px;
    resize: none;
    font-family: "Poppins", sans-serif;

  }
  
  .add-blog-btn {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    transition: background-color 1s ease;
  }
  
  .add-blog-btn:hover {
    background-color:rgb(14, 88, 46);
  }
  .language-btn {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color:green;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.blogImage {
  height: 37vh;
    object-fit: contain;
    width: 89%;
}