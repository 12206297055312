.error-page{
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    min-height:calc(75vh - 0px);
}
.error-page h1{
    color: #545454;
    font-size: 50px;
    margin-bottom: 0;
    font-family:'Courier New', Courier, monospace;
}
.error-page p{
    font-size: 23px;
    margin-top: 0;
    color: #545454;
    font-family: "Poppins", sans-serif;
}
.goBack {
    width: 250px;
    border-radius: 20px;
    margin-top: 30px;
    padding: 10px;
    border: none;
    background-color: green;
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    display: block;
    text-decoration: none;
  }
  .error-logo  > img{
    position: absolute;
    bottom: 94px;
    z-index: -10;
    left: 0;
    width: 15%;
  }