header {
  align-items: center;
  display: flex;
  padding-top: 1%;
  flex-wrap: wrap;
  width: 100%;
  /* position: fixed; */
  z-index: 100;
  background: white;
  font-family: "Poppins", sans-serif;
  justify-content: space-around;
}
.Nav-list {
  padding: 0;
  display: flex;
  gap: 3rem;
  flex-wrap: wrap;
  justify-content: center;
}

.Nav-list > li {
  list-style-type: none;
}

.Nav-list a {
  color: black;
  text-decoration: none;
}
.Nav-list .active {
  border-bottom: 2px solid green;
}

.social-links {
  display: flex;
  gap: 1rem;
  z-index: 10;
  position: relative;
}
.downloadButton {
  background-color: green;
  padding: 7px;
  margin-top: -5px;
  font-weight: 500;
  border-radius: 10px;
}
.downloadButton > a {
  color: white;
}
.iconBox{
  padding: 10px;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  color: black;
  border: 1px solid black;
}
/* Header.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-box {
  display: flex;
  padding: 3%;
  padding-top: 3%;
  position: relative; 
  padding-bottom: 3%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.3); 
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #686868;
  font-size: 25px;
}

.modal-content {
  text-align: center;
  margin: auto;
}
.downlaod-link img {
  width: 200px;
  margin-right: 20px;
}