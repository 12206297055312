/* medium sizes */
@media screen and (max-width: 1235px) {
  .links {
    gap: 1rem !important;
  }
}

@media screen and (max-width: 1013px) {
  .downlaod-app {
    width: 80% !important;
  }
  .container {
    margin-top: 10% !important;
  }
  .homePage-logo > img {
    width: 100% !important;
  }
  .homePage-logo {
    width: 50%;
  }
  .row {
    align-items: center;
  }
  .card-row{
    width: 300px;
  }
  .Nav-list  {
    gap: 14px !important;
  }
}
@media screen and (max-width: 783px) {
  .section-heading {
    font-size: 30px !important;
  }
  .homePage-logo {
    width: 58%;
  }
  .dailyReview-heading > p {
    line-height: 1.2 !important;
  }
  .footerSocials {
    margin-bottom: 16px;  
    width: 100% !important;
    justify-content: space-evenly !important;
  }
  .footerContainer {
    padding: 14px !important;
    flex-direction: column !important;
  }
  .content > h2 {
    font-size: 33px !important;
}
.copyrightBox {
  margin-left: auto !important;
}
}
/* smal dvices sizes */
@media screen and (max-width: 695px) {
  .row {
    flex-wrap: wrap !important;
    margin: auto;
  }
  .reverse {
    flex-direction: column-reverse;
  }
  .content {
    width: 95% !important;
  }
  .homePage-logo {
    width: 100%;
    margin-top: 15px;
  }
  .homePage-logo > img {
    width: 96% !important;
  }
  .mob-mockup > img {
    margin-top: 70px;
  }
  .AppInfo-conatiner{
    flex-wrap: wrap;
  }
.card-row{
  width: 90%;
  text-align: center;

}
.procces-name {
  width: 65%;
}
.solve-text {
  margin-right: - 11px;
}
.marketReports-content {
  width: 95% !important;
}
.downlaod-button img {
  width: 43% !important;
  margin-left: 10px;

}
.modal-box {
  width: 76% !important;
  padding: 4% !important;
}
.modal-content {
  /* width: 80%; */
  text-align: center;
  margin: auto;
}
.downlaod-link img {
 margin-top: 5%;
}
.process-container {
  width: 331px !important;
}
footer{
  margin-top: 7% !important;
}
.error-page h1{
  font-size: 40px !important;
  margin-bottom: 10px !important;

}
.error-page p{
  font-size: 20px !important;

}
}
