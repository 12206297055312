@import url('https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap');
@import "./Responsive.css";
body {
  margin: 0;
}
.container {
  /* font-family: "Inter", sans-serif; */
  max-width: 95vw;
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content {
  width: 673px;
}
.section-para {
  margin-bottom: 20px;
}
.section-heading {
  margin-bottom: 0;
  text-transform: capitalize;
  font-size: 45px;
  font-weight: 800;
}
.content > h1 > span {
  color: green;
}
.homePage-logo > img {
  width: 36vw;
  object-fit: contain;
}
.downlaod-button img {
  width: 34%;
  margin-right: 10px;
}
.review-box > img {
  width: 35px;
  height: 30px;
  border-radius: 100px;
}
.review-count {
  width: 35px;
  height: 30px;
  font-size: 10px;
  text-align: center;
  border-radius: 100px;
  display: inline-block;
  background-color: green;
}
.review-count > p {
  color: white;
}
.dailyReview-heading :first-child {
  font-weight: bold;
  font-size: 12px;
}
.dailyReview-heading :nth-child(2) {
  font-size: 12px;
}
.dailyReview-heading > p {
  line-height: 0.3;
  margin-left: 10px;
}
.review-box {
  align-items: center;
  display: flex;
}
/* secound section */
.secound {
  justify-content: center;
}
.secound > h1 {
  text-align: center;
}
.secound .content {
  margin-top: 0;
}
.secound .solve-boxes p {
  color: black;
  margin-top: 0;
}
.secound .solve-boxes .sectionSubHeading {
  margin-bottom: 0;

}
.big-heading{
  font-size: 30px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: green;
  color: white;
}
.content button {
  width: 150px;
  border-radius: 20px;
  margin-top: 30px;
  padding: 10px;
  border: none;
  background-color: green;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
.solve-boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.solve-text {
width: 76%;}
/* third sesction */
.learnMore-btn {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid gray;
  border-radius: 40px;
  color: gray;
  background-color: transparent;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}
/* appInfo-card section */
.AppInfo-conatiner{
  width: 90%;
  display: flex;
  margin: auto;
  margin-top: 30px;
  border-radius: 50px;
  padding-bottom: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.appInfo-card{
  width: 130px;
  display: flex;
  margin-top: 54px;
  align-items: center; 
  flex-direction: column;
}
.appInfo-card h2{
  line-height: 0;
}
.appInfo-card p{
  line-height: 0;
}
/* how It work section */
.howItWork-Section{
  display: flex;
  flex-wrap: wrap;
}
.process-container{
  text-align: center;
  width: 260px;
  margin-top: 4%;
}
.steps{
  text-align: center;
  margin-top: 4%;
}
.process{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Tilt Neon", sans-serif;

}
.procces-name{
  font-weight: bolder;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
}
.proces-image{
  width: 63%;
}
  