footer {
  margin-top: 0;
  background: green;
  margin-bottom: -10px;
  padding-bottom: 5px;
  height: auto;
}
.footerSocials {
  display: flex;
  align-items: center;
  width: 20%;
  gap: 1rem;
}
.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}
.downlaod-app {
  width: 23%;
}
.downlaod-app img {
  width: 45%;
  margin-top: 15px;
  margin-left: 10px;
}
.links {
  display: flex;
  color: white;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0px;
  justify-content: center;
}
.footerNavLinks a {
  color: white;
  text-decoration: none;
  width: 100%;
  font-size: 18px;
  width: 50%;
  font-family: "Poppins", sans-serif;
}
.copyrightBox {
  text-align: center;
  font-size: 15px;
  color: white;
  padding: 10px 0px;
  margin-left: -8%;
}
.links > li {
  list-style-type: none;
  padding-top: 4px;
  font-size: 18px;
}
.icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: black;
}
.footer-icon {
  color: white;
  font-size: 1.5rem;
  margin-top: 5px;
}